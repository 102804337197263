<template>
  <div class="user-info-frame">
    <p class="guide">‘ <strong>*</strong> ’ 항목은 입력 필수 항목입니다.</p>
    <div class="line"></div>
    <div class="info-container">
      <h5>아이디<strong>*</strong></h5>
      <input type="text" v-model="id" placeholder="아이디 입력" class="custom-input-box w350"
             @input="someHandler($event)"/>
      <button class="check-double" @click="checkDouble">중복확인</button>
    </div>
    <div class="info-container">
      <h5>이메일</h5>
      <input type="text" v-model="email" placeholder="이메일 입력" class="custom-input-box w444"/>
    </div>
    <form>
      <div class="info-container">
        <h5>휴대폰번호<strong>*</strong></h5>
        <input type="tel" v-model="mobile" placeholder="‘-’없이 번호 입력" class="custom-input-box w444" maxlength="11" autocomplete="off"/>
      </div>
      <div class="info-container">
        <h5>비밀번호<strong>*</strong></h5>
        <input type="password" v-model="password" placeholder="영소문자+숫자조합 8자리 이상" class="custom-input-box w444" autocomplete="off"/>
      </div>
      <div class="info-container">
        <h5>비밀번호 확인<strong>*</strong></h5>
        <input type="password" v-model="repassword" placeholder="영소문자+숫자조합 8자리 이상" class="custom-input-box w444" autocomplete="off"/>
      </div>
    </form>
    <div class="info-container">
      <h5>이름<strong>*</strong></h5>
      <input type="text" v-model="userName" placeholder="12자리 이내 입력" class="custom-input-box w444" maxlength="12"/>
    </div>
    <div class="info-container">
      <h5>생년월일<strong>*</strong></h5>
      <mdb-date-picker-2 class="input-box bg-white w444 search-select " outline placeholder="날짜 선택" type="text" size="lg" v-model="birth" title="생년월일 선택" :options="options"/>
    </div>
    <div class="info-container">
      <h5>성별<strong>*</strong></h5>
      <mdb-form-inline style="width: 444px">
        <mdb-input type="radio" id="gender-1" name="groupOfMaterialRadios2" radioValue="남성" v-model="gender" label="남성"/>
        <mdb-input type="radio" id="gender-2" name="groupOfMaterialRadios2" radioValue="여성" v-model="gender" label="여성"/>
      </mdb-form-inline>
    </div>
    <div class="line-thin"></div>
    <div class="btn-line">
      <button class="prev-btn" @click="prevStep">이전</button>
      <button class="next-btn" @click="nextStep">다음</button>
    </div>
  </div>
</template>

<script>

import {mdbDatePicker2, mdbFormInline, mdbInput} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "UserPersonalInfoView",
  components: {
    mdbInput,
    mdbFormInline,
    mdbDatePicker2,
  },
  data() {
    return {
      fbCollection: 'users',
      id: '',
      email: '',
      mobile: '',
      password: '',
      repassword: '',
      userName: '',
      birthYear: '',
      birthMonth: '',
      birthday: '',
      gender: '',
      birth: '',
      isDoubleCheck: false,
      options: {
        month: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월'
        ],
        week: ['일', '월', '화', '수', '목', '금', '토'],
        color: {
          header: 'info',
          headerText: 'white',
          checkedDay: 'info'
        },
        buttons: {
          ok: '확인',
          cancel: '취소',
          clear: '초기화'
        },
      },
      userInfo: {
        birth: '',
        id: '',
        email: '',
        gender: '',
        mobile: '',
        name: '',
      }
    }
  },
  methods: {
    someHandler() {
      const self = this
      self.isDoubleCheck = false
    },
    checkDouble() {
      const self = this;
      if (self.id.trim().length === 0) {
        alert('아이디를 입력하세요.');
        return
      }
      if (!self.checkId()) {
        return false
      }
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      defRef.where('id', '==', self.id)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              alert('사용가능한 아이디 입니다.');
              self.isDoubleCheck = true
            } else {
              alert('이미 등록된 아이디 입니다.');
              self.id = '';
              self.isDoubleCheck = true
            }
          });
    },
    checkId() {
      const self = this;
      const exptext = /^[A-Za-z0-9]{4,18}$/;
      if (exptext.test(self.id) === false) {
        //아이디 형식이 영문과 숫자 4~18자 형식이 아닐경우
        alert("영문과 숫자 4~18자 이내로 입력하세요.");
        return false;
      }
      return true;
    },
    prevStep() {
      const self = this;
      self.$emit('setStep', 0);
    },
    nextStep() {
      const self = this;
      if (self.checkValidate()) {
        self.$emit('setStep', 2, self.userInfo, self.password);
      }
    },
    checkEmail() {
      const self = this;
      const exptext = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (exptext.test(self.email) === false) {
        //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우
        alert("이메일형식이 올바르지 않습니다.");
        return false;
      }
      return true;
    },
    checkMobile() {
      const self = this;
      const exptext = /^\d{3}\d{3,4}\d{4}$/;
      const exptext2 = /^\d{3}-\d{3,4}-\d{4}$/;
      if (exptext.test(self.mobile) === false && exptext2.test(self.mobile) === false) {
        alert('전화번호 형식이 올바르지 않습니다.')
        return false;
      }
      self.mobile = self.mobile.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1$2$3");
      return true;
    },
    checkPassword() {
      const self = this;
      const exptext = new RegExp("^(?=.*[0-9])(?=.*[A-Za-z])^[a-zA-Z0-9]{8,}$");
      if (exptext.test(self.password) === false) {
        alert('비밀번호 형식이 올바르지 않습니다.')
        self.isProgress = false;
        return false;
      }
      return true;
    },
    checkName() {
      const self = this;
      const special_regx = /[~!@#$%^&*()_+|<>?:{}]/;

      if (special_regx.test(self.userName)) {
        alert('이름에 특수문자를 넣을 수 없습니다.')
        return false;
      }
      return true;
    },
    checkValidate() {
      const self = this;

      //아이디 입력 확인
      if (self.id.trim().length === 0) {
        alert('아이디를 입력하세요.')
        return false
      } else if (!self.isDoubleCheck) {
        alert('아이디 중복확인을 해주세요.')
        return false
      }
      //이메일이 있는 경우 확인
      if (self.email !== '') {
        if (!self.checkEmail()) {
          return false
        }
      }
      //휴대폰번호 확인
      if (self.mobile.trim().length === 0) {
        alert('휴대폰번호를 입력하세요.')
        return false
      }
      if (!self.checkMobile()) {
        return false
      }
      //비밀번호 확인
      if (self.password.trim().length !== 0 || self.repassword.trim().length !== 0) {
        if (self.repassword === '') {
          alert('비밀번호 확인란을 입력해주세요')
          return
        } else if (self.password !== self.repassword) {
          alert('입력한 비밀번호가 다릅니다')
          return
        } else if (self.password.length < 8 || self.repassword.length < 8) {
          alert('8자리 이상의 비밀번호로 설정해주세요')
          return
        } else if (self.password.trim().length === 0) {
          alert('비밀번호를 입력하세요.')
          return false
        }
        if (!self.checkPassword()) {
          return false
        }
      }
      //이름 확인 (특수문자 입력불가)
      if (self.userName.trim().length === 0) {
        alert('이름을 입력하세요.')
        return false
      }
      if (!self.checkName()) {
        return false
      }

      if (self.birth == null || self.birth === '') {
        alert('생년월일을 입력하세요.')
        return false
      } else if (self.gender === '') {
        alert('성별을 입력하세요.')
        return false
      }

      self.userInfo = {
        birth: this.birth,
        email: this.email,
        gender: this.gender,
        mobile: this.mobile,
        name: this.userName,
        id: this.id,
      }

      return true
    },
  }
}
</script>

<style scoped>
.user-info-frame {
  padding-top: 40px;
}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
}

strong {
  color: #E14F4E;
}

.line {
  width: 805px;
  height: 2px;
  background-color: #000000;
  margin: 40px 0 30px;
}

.line-thin {
  width: 805px;
  height: 1px;
  background-color: #000000;
  margin: 35px 0 30px;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}


.info-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #272833;
  width: 150px;
  margin-right: 10px;
}

.w444 {
  width: 444px;
}

/deep/ .w350 {
  width: 334px !important;
}

/deep/ .search-select .md-form {
  margin: 0 !important;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #000000;
  margin: 0;
  height: 40px;
}

/deep/ .diagnosis-search .form-control::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
}

.info-container /deep/ .mr-5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-right: 10px !important;
  padding-left: 30px !important;
}

.btn-line {
  text-align: right;
}

.next-btn {
  width: 128px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.prev-btn {
  width: 128px;
  height: 40px;
  margin-right: 10px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
}

.check-double {
  width: 100px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  margin-left: 10px;
}


</style>
