<template>
  <div class="complete-frame">

    <div class="line"></div>
    <img src="@/assets/images/IA/img_success.png" alt="success">
    <h2>회원가입이 완료되었습니다.</h2>
    <p>이제 등록된 계정으로 로그인 후</p>
    <p>서비스를 이용할실 수 있습니다.</p>

    <div>
      <button class="btn-login" @click="login">환자 로그인</button>
    </div>
    <div>
      <button class="btn-go-home" @click="home">홈으로 이동</button>
    </div>

  </div>

</template>

<script>


export default {
  name: "UserCompleteView",
  methods: {
    async login() {
      const self = this;
      // await firebase.auth().signOut()
      await self.$router.replace('/user/login');
    },

    async home() {
      const self = this;
      // await firebase.auth().signOut()
      await self.$router.replace('/');
    }
  }
}
</script>

<style scoped>
.complete-frame {
  text-align: center;
}

.line {
  width: 805px;
  height: 2px;
  background-color: #000000;
  margin: 60px 0;
}

.complete-frame img {
  width: 60px;
  height: 60px;
}

.complete-frame h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #272833;
  margin: 30px 0;
}

.guide p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0;
}

.btn-go-home {
  width: 402px;
  height: 40px;
  background: #222539;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.btn-login {
  border: 0;
  width: 402px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  margin: 60px 0 10px;
}

</style>
